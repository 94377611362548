import React, { CSSProperties } from "react";

import { useCSVReader } from "react-papaparse";

const styles = {
  csvReader: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "20px",
    width: "100%",
  },
  browseFile: {
    width: "100%",
    height: "35px",
    borderRadius: "24px",
    color: "white",
    backgroundColor: "#646464",
  },
  acceptedFile: {
    border: "1px solid #ccc",
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: "80%",
  },
  remove: {
    borderRadius: 0,
    padding: "0 20px",
  },
  progressBarBackgroundColor: {
    backgroundColor: "#0BDEDE",
    marginRight: "0",
    marginBottom: "20px",
  },
};

export default function CSVReader({ onData }) {
  const { CSVReader } = useCSVReader();

  return (
    <CSVReader
      onUploadAccepted={(results) => {
        console.log("---------------------------");
        console.log(results);
        console.log("---------------------------");
        let recepients = results.data.slice(1);
        onData(JSON.stringify(recepients));
      }}
      config={{ delimiter: "" }}
    >
      {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
        <>
          <div style={styles.csvReader}>
            <button type="button" {...getRootProps()} style={styles.browseFile}>
              {acceptedFile && acceptedFile.name
                ? acceptedFile && acceptedFile.name
                : "Select File"}
            </button>
            {/* <button {...getRemoveFileProps()} style={styles.remove}>
              Remove
            </button> */}
            <p
              style={{
                width: "100%",
                fontSize: "10px",
                textAlign: "left",
                marginTop: "5px",
              }}
            >
              * CSV Files Only
            </p>
          </div>
          <ProgressBar style={styles.progressBarBackgroundColor} />
        </>
      )}
    </CSVReader>
  );
}
