import { useState } from "react";
import { format } from "date-fns";

import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

export default function DatePickerCustom() {
  const [selected, setSelected] = useState();

  let footer = <p>Please pick a day.</p>;
  if (selected) {
    footer = (
      <p style={{ color: "white" }}>You picked {format(selected, "PP")}.</p>
    );
  }
  console.log("selected: ", selected);
  return (
    <DayPicker
      mode="single"
      captionLayout="dropdown-buttons"
      showOutsideDays
      fromYear={2015}
      toYear={2025}
      selected={selected}
      onSelect={setSelected}
      // footer={footer}
    />
  );
}
