import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Sidebar = ({ title }) => {
  const location = useLocation();

  const [isLightMode, setIsLightMode] = useState(false);

  function changeState() {
    setIsLightMode(!isLightMode);
    toggleTheme();
  }

  const toggleTheme = () => {
    const hasSidebar = document.querySelector(".sidebar") !== null;

    if (hasSidebar) {
      document.body.setAttribute("data-theme", isLightMode ? "dark" : "light");
    }
  };

  useEffect(() => {
    document.title = title ? title : "Dashboard";
  }, [location]);

  return (
    <aside className="sidebar flex flex-direction-column align-center">
      <NavLink activeclassname="active" className={"brand"} to="/">
        <svg
          width={150}
          height={76}
          viewBox="0 0 150 76"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <path
            d="M4.23912 45.5025C1.41224 42.5156 0 38.8522 0 34.5125C0 30.1681 1.41224 26.4931 4.23912 23.4853C7.07079 20.4728 10.6673 18.9666 15.0287 18.9666C19.0377 18.9666 22.3369 20.1846 24.9264 22.6206V19.822H35.3612V49.1286H25.0511V45.8186H24.9264C22.3369 48.5963 19.0377 49.984 15.0287 49.984C10.6673 49.984 7.07079 48.4917 4.23912 45.5025ZM13.1106 29.5382C11.7535 30.8352 11.0773 32.4925 11.0773 34.5125C11.0773 36.5278 11.7247 38.1735 13.0243 39.4496C14.3214 40.7211 16.031 41.3557 18.1553 41.3557C20.1934 41.3557 21.8717 40.7118 23.1905 39.4217C24.5068 38.127 25.1662 36.4883 25.1662 34.5032C25.1662 32.4902 24.4876 30.8352 23.1329 29.5382C21.7758 28.2434 20.1166 27.5949 18.1553 27.5949C16.1461 27.5949 14.4653 28.2434 13.1106 29.5382Z"
            fill="currentColor"
          />
          <path
            d="M38.1328 49.1296V0H48.558V49.1296H38.1328Z"
            fill="currentColor"
          />
          <path
            d="M53.0945 19.8233V8.91699H63.5197V19.8233H69.8879V27.5405H63.5197V36.8476C63.5197 39.5509 64.3061 40.9015 65.879 40.9015C66.2746 40.9015 66.687 40.8271 67.1162 40.6783C67.5502 40.5249 67.8859 40.3715 68.1232 40.2227L68.4781 39.9903L71.0676 48.163C68.8282 49.3764 66.2746 49.9854 63.4046 49.9854C61.4409 49.9854 59.7529 49.653 58.3407 48.9905C56.926 48.3211 55.8567 47.4262 55.1278 46.3035C54.3989 45.1831 53.8762 44.0023 53.5645 42.761C53.2504 41.5221 53.0945 40.1995 53.0945 38.7908V27.5405H48.7307V19.8233H53.0945Z"
            fill="currentColor"
          />
          <path
            d="M86.381 27.2499C84.4173 27.2499 82.7269 27.9566 81.3075 29.3698C79.8929 30.7784 79.1879 32.4706 79.1879 34.4464C79.1879 36.4687 79.8929 38.1818 81.3075 39.5881C82.7269 40.9967 84.4173 41.6987 86.381 41.6987C87.3209 41.6987 88.2128 41.5871 89.0568 41.364C89.9056 41.1362 90.5266 40.9061 90.9174 40.676L91.4449 40.3319L94.9839 47.3611C94.7082 47.547 94.3246 47.7841 93.833 48.0677C93.3391 48.3536 92.2218 48.7441 90.4763 49.2392C88.7307 49.7343 86.8534 49.9831 84.8465 49.9831C80.4084 49.9831 76.5073 48.4722 73.1457 45.4457C69.789 42.4146 68.1106 38.7862 68.1106 34.558C68.1106 30.295 69.789 26.6316 73.1457 23.568C76.5073 20.4997 80.4084 18.9656 84.8465 18.9656C86.8534 18.9656 88.702 19.1957 90.3899 19.6536C92.0779 20.1069 93.2768 20.5625 93.9865 21.0204L94.9839 21.7084L91.4449 28.6167C90.1526 27.7055 88.4646 27.2499 86.381 27.2499Z"
            fill="#0BDEDE"
          />
          <path
            d="M96.7067 19.8233V8.91699H107.132V19.8233H113.5V27.5405H107.132V36.8476C107.132 39.5509 107.918 40.9015 109.491 40.9015C109.887 40.9015 110.299 40.8271 110.728 40.6783C111.162 40.5249 111.498 40.3715 111.735 40.2227L112.09 39.9903L114.68 48.163C112.44 49.3764 109.887 49.9854 107.017 49.9854C105.053 49.9854 103.365 49.653 101.953 48.9905C100.538 48.3211 99.4688 47.4262 98.7399 46.3035C98.011 45.1831 97.4883 44.0023 97.1766 42.761C96.8625 41.5221 96.7067 40.1995 96.7067 38.7908V27.5405H92.3429V19.8233H96.7067Z"
            fill="#0BDEDE"
          />
          <path
            d="M113.905 49.1282V19.8215H124.33V24.052H124.445C124.526 23.8986 124.656 23.7056 124.828 23.4755C125.006 23.2477 125.389 22.8479 125.979 22.2761C126.567 21.7066 127.193 21.1952 127.859 20.7419C128.53 20.284 129.396 19.8749 130.458 19.5146C131.518 19.1497 132.599 18.9661 133.7 18.9661C134.836 18.9661 135.956 19.1195 137.057 19.4217C138.155 19.7262 138.96 20.0307 139.474 20.3328L140.298 20.7977L135.935 29.361C134.642 28.2964 132.837 27.7618 130.516 27.7618C129.262 27.7618 128.18 28.0291 127.274 28.5614C126.373 29.096 125.732 29.7422 125.356 30.5046C124.984 31.2671 124.718 31.9156 124.56 32.4479C124.406 32.9825 124.33 33.4009 124.33 33.7031V49.1282H113.905Z"
            fill="#0BDEDE"
          />
          <path
            d="M138.957 49.1296V0H149.382V49.1296H138.957Z"
            fill="#0BDEDE"
          />
          <path
            d="M45.899 55.8525L49.5722 64.6855H47.3472L46.6662 62.9189H43.204L42.523 64.6855H40.298L43.9712 55.8525H45.899ZM45.9661 61.1523L44.9495 58.5768H44.9207L43.9041 61.1523H45.9661Z"
            fill="currentColor"
          />
          <path
            d="M55.0283 55.8513V62.9177H58.1645V64.6842H52.9471V55.8513H55.0283Z"
            fill="currentColor"
          />
          <path
            d="M61.6202 57.6179V55.8513H68.0269V57.6179H65.8689V64.6842H63.7878V57.6179H61.6202Z"
            fill="currentColor"
          />
          <path
            d="M77.0044 55.8525V57.6191H73.8682V59.3764H76.8701V61.143H73.8682V62.9189H77.0044V64.6855H71.787V55.8525H77.0044Z"
            fill="currentColor"
          />
          <path
            d="M81.2178 55.8525H84.4786C85.3538 55.8525 86.0659 56.0803 86.6174 56.5313C87.1736 56.9845 87.4518 57.6331 87.4518 58.4745C87.4518 59.1254 87.2815 59.6716 86.9435 60.1109C86.6102 60.5456 86.157 60.8524 85.5816 61.0314L88.7178 64.6855H86.0803L83.299 61.1895V64.6855H81.2178V55.8525ZM83.299 59.7669H83.5387C83.7306 59.7669 83.8888 59.7646 84.0183 59.7576C84.1525 59.753 84.3012 59.7274 84.469 59.6832C84.6417 59.6414 84.7783 59.581 84.8814 59.5066C84.9893 59.4275 85.0781 59.3113 85.15 59.1626C85.2267 59.0091 85.2651 58.8255 85.2651 58.614C85.2651 58.3978 85.2267 58.2142 85.15 58.0654C85.0781 57.9167 84.9893 57.8051 84.8814 57.7307C84.7783 57.6517 84.6417 57.5889 84.469 57.5447C84.3012 57.5029 84.1525 57.4773 84.0183 57.4704C83.8888 57.4587 83.7306 57.4518 83.5387 57.4518H83.299V59.7669Z"
            fill="currentColor"
          />
          <path
            d="M94.2091 59.1997V64.6855H92.1279V55.8525H94.2091L97.8344 61.3289H97.8536V55.8525H99.9252V64.6855H97.8536L94.2283 59.1997H94.2091Z"
            fill="currentColor"
          />
          <path
            d="M108.9 55.8525L112.573 64.6855H110.348L109.667 62.9189H106.205L105.524 64.6855H103.299L106.972 55.8525H108.9ZM108.967 61.1523L107.95 58.5768H107.922L106.905 61.1523H108.967Z"
            fill="currentColor"
          />
          <path
            d="M115.358 57.6191V55.8525H121.765V57.6191H119.607V64.6855H117.526V57.6191H115.358Z"
            fill="currentColor"
          />
          <path
            d="M127.605 55.8525V64.6855H125.524V55.8525H127.605Z"
            fill="currentColor"
          />
          <path
            d="M134.787 64.6855L130.97 55.8525H133.31L135.612 61.6544L137.904 55.8525H140.244L136.427 64.6855H134.787Z"
            fill="currentColor"
          />
          <path
            d="M148.837 55.8525V57.6191H145.701V59.3764H148.703V61.143H145.701V62.9189H148.837V64.6855H143.62V55.8525H148.837Z"
            fill="currentColor"
          />
          <path
            d="M79.3914 74.0192C79.7031 74.0192 80.01 73.9727 80.3121 73.8797C80.6119 73.7868 80.8324 73.6984 80.9739 73.6101L81.1753 73.4706L82.0673 75.1907C82.0337 75.2093 81.9929 75.2372 81.9426 75.2744C81.8898 75.3116 81.7699 75.3813 81.5781 75.479C81.3911 75.5719 81.1897 75.6556 80.9739 75.73C80.7629 75.7997 80.4848 75.8602 80.1395 75.916C79.7942 75.9718 79.4346 75.9996 79.0653 75.9996C78.2214 75.9996 77.4181 75.7997 76.6581 75.3953C75.9028 74.9932 75.2914 74.4376 74.8262 73.731C74.3587 73.0244 74.1261 72.2619 74.1261 71.4437C74.1261 70.8184 74.2628 70.2211 74.5385 69.6492C74.8118 69.0798 75.1739 68.5939 75.6223 68.1895C76.0754 67.7804 76.6029 67.455 77.2047 67.2132C77.8114 66.9668 78.4324 66.8413 79.0653 66.8413C79.66 66.8413 80.2018 66.911 80.6958 67.0459C81.1945 67.183 81.5446 67.3201 81.7508 67.455L82.0673 67.6595L81.1753 69.3703C81.1226 69.3285 81.0458 69.2773 80.9451 69.2215C80.842 69.1611 80.6334 69.0798 80.3217 68.9798C80.0148 68.8752 79.7031 68.8217 79.3914 68.8217C78.8927 68.8217 78.4419 68.9031 78.0391 69.0635C77.6411 69.2192 77.3294 69.4261 77.0992 69.6864C76.8691 69.9421 76.6916 70.2164 76.5717 70.5139C76.4495 70.8115 76.3895 71.1137 76.3895 71.4158C76.3895 72.0922 76.6485 72.6966 77.1664 73.2289C77.6843 73.7566 78.4252 74.0192 79.3914 74.0192Z"
            fill="currentColor"
          />
          <path
            d="M85.4672 71.4166C85.4672 70.1404 85.9179 69.0596 86.8195 68.1716C87.721 67.286 88.8048 66.842 90.0707 66.842C91.3415 66.842 92.4229 67.286 93.3124 68.1716C94.2067 69.0526 94.6551 70.1335 94.6551 71.4166C94.6551 72.7136 94.2163 73.8014 93.3412 74.6801C92.4636 75.5611 91.3751 76.0004 90.0707 76.0004C88.7328 76.0004 87.6299 75.5634 86.7619 74.6894C85.8987 73.8154 85.4672 72.7252 85.4672 71.4166ZM87.7114 71.4166C87.7114 71.8815 87.7833 72.3068 87.932 72.6904C88.0782 73.0692 88.3372 73.3923 88.7088 73.6573C89.0781 73.9246 89.5336 74.0571 90.0707 74.0571C90.8428 74.0571 91.4254 73.8014 91.8163 73.2854C92.2119 72.7717 92.4109 72.1488 92.4109 71.4166C92.4109 70.6983 92.2095 70.0823 91.8067 69.5663C91.4038 69.0456 90.8236 68.7853 90.0707 68.7853C89.3155 68.7853 88.7328 69.0456 88.3252 69.5663C87.9152 70.0823 87.7114 70.6983 87.7114 71.4166Z"
            fill="currentColor"
          />
          <path
            d="M100.646 70.3567V75.8424H98.5643V67.0095H100.646L104.271 72.4859H104.29V67.0095H106.362V75.8424H104.29L100.665 70.3567H100.646Z"
            fill="currentColor"
          />
          <path
            d="M110.135 68.7761V67.0095H116.541V68.7761H114.383V75.8424H112.302V68.7761H110.135Z"
            fill="currentColor"
          />
          <path
            d="M120.294 67.0093H123.555C124.43 67.0093 125.142 67.2371 125.693 67.688C126.25 68.1413 126.528 68.7898 126.528 69.6313C126.528 70.2821 126.357 70.8284 126.019 71.2677C125.686 71.7023 125.233 72.0092 124.658 72.1882L127.794 75.8422H125.156L122.375 72.3462V75.8422H120.294V67.0093ZM122.375 70.9237H122.615C122.806 70.9237 122.965 70.9213 123.094 70.9144C123.228 70.9097 123.377 70.8841 123.545 70.84C123.718 70.7981 123.854 70.7377 123.957 70.6633C124.065 70.5843 124.154 70.4681 124.226 70.3193C124.303 70.1659 124.341 69.9823 124.341 69.7707C124.341 69.5546 124.303 69.3709 124.226 69.2222C124.154 69.0734 124.065 68.9618 123.957 68.8874C123.854 68.8084 123.718 68.7456 123.545 68.7015C123.377 68.6596 123.228 68.6341 123.094 68.6271C122.965 68.6155 122.806 68.6085 122.615 68.6085H122.375V70.9237Z"
            fill="currentColor"
          />
          <path
            d="M130.795 71.4166C130.795 70.1404 131.245 69.0596 132.147 68.1716C133.049 67.286 134.132 66.842 135.398 66.842C136.669 66.842 137.75 67.286 138.64 68.1716C139.534 69.0526 139.983 70.1335 139.983 71.4166C139.983 72.7136 139.544 73.8014 138.669 74.6801C137.791 75.5611 136.703 76.0004 135.398 76.0004C134.06 76.0004 132.957 75.5634 132.089 74.6894C131.226 73.8154 130.795 72.7252 130.795 71.4166ZM133.039 71.4166C133.039 71.8815 133.111 72.3068 133.26 72.6904C133.406 73.0692 133.665 73.3923 134.036 73.6573C134.406 73.9246 134.861 74.0571 135.398 74.0571C136.17 74.0571 136.753 73.8014 137.144 73.2854C137.539 72.7717 137.738 72.1488 137.738 71.4166C137.738 70.6983 137.537 70.0823 137.134 69.5663C136.731 69.0456 136.151 68.7853 135.398 68.7853C134.643 68.7853 134.06 69.0456 133.653 69.5663C133.243 70.0823 133.039 70.6983 133.039 71.4166Z"
            fill="currentColor"
          />
          <path
            d="M145.99 67.0095V74.0759H149.127V75.8424H143.909V67.0095H145.99Z"
            fill="currentColor"
          />
        </svg>
      </NavLink>
      <ul
        className="sidebar-navigation flex flex-direction-column"
        role="navigation"
      >
        <li>
          <NavLink to="/" aria-hidden="true">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3H4C3.44772 3 3 3.44772 3 4V11C3 11.5523 3.44772 12 4 12H9C9.55228 12 10 11.5523 10 11V4C10 3.44772 9.55228 3 9 3Z"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20 3H15C14.4477 3 14 3.44772 14 4V7C14 7.55228 14.4477 8 15 8H20C20.5523 8 21 7.55228 21 7V4C21 3.44772 20.5523 3 20 3Z"
                stroke="#0BDEDE"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20 12H15C14.4477 12 14 12.4477 14 13V20C14 20.5523 14.4477 21 15 21H20C20.5523 21 21 20.5523 21 20V13C21 12.4477 20.5523 12 20 12Z"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 16H4C3.44772 16 3 16.4477 3 17V20C3 20.5523 3.44772 21 4 21H9C9.55228 21 10 20.5523 10 20V17C10 16.4477 9.55228 16 9 16Z"
                stroke="#0BDEDE"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="active" to="/leads" aria-hidden="true">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 21C18 19.4087 17.3679 17.8826 16.2426 16.7574C15.1174 15.6321 13.5913 15 12 15C10.4087 15 8.88258 15.6321 7.75736 16.7574C6.63214 17.8826 6 19.4087 6 21"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 15C14.2091 15 16 13.2091 16 11C16 8.79086 14.2091 7 12 7C9.79086 7 8 8.79086 8 11C8 13.2091 9.79086 15 12 15Z"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Leads
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="active" to="/broad">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                d="M6.9001 19.0999C3.0001 15.1999 3.0001 8.7999 6.9001 4.8999"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.8002 16.2C6.5002 13.9 6.5002 10.1 8.8002 7.69995"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
                stroke="#0BDEDE"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.2002 7.80005C17.5002 10.1 17.5002 13.9 15.2002 16.3"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.1001 4.8999C21.0001 8.7999 21.0001 15.0999 17.1001 18.9999"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Broadcast
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="active" to="/calender">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 2V6"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 2V6"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3 10H21"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 14H8.01"
                stroke="#0BDEDE"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 14H12.01"
                stroke="#0BDEDE"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 14H16.01"
                stroke="#0BDEDE"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 18H8.01"
                stroke="#0BDEDE"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 18H12.01"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 18H16.01"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Calendar
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="active" to="/chat">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                d="M13.5 3.1C13 3.1 12.5 3 12 3C11.5 3 11 3.1 10.5 3.1"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.2999 6.79995C18.7035 6.00355 17.9964 5.29641 17.2 4.69995"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.9 13.5C21 13 21 12.5 21 12C21 11.5 20.9 11 20.9 10.5"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.2 19.2999C17.9964 18.7035 18.7035 17.9964 19.2999 17.2"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.5 20.8999C11 20.9999 11.5 20.9999 12 20.9999C12.5 20.9999 13 20.8999 13.5 20.8999"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.5 17.5L2 22L6.5 20.5"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.1 10.5C3.1 11 3 11.5 3 12C3 12.5 3.1 13 3.1 13.5"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.79995 4.69995C6.00355 5.29641 5.29641 6.00355 4.69995 6.79995"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Conversations
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="active" to="/analytics">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                d="M18 20V10"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 20V10"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 20V14"
                stroke="#0BDEDE"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Analytics
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="active" to="/pipline">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                d="M12.83 7.17987C12.5694 7.06102 12.2864 6.99951 12 6.99951C11.7136 6.99951 11.4305 7.06102 11.17 7.17987L2.59996 11.0799C2.42251 11.1581 2.27164 11.2863 2.16573 11.4487C2.05981 11.6112 2.00342 11.8009 2.00342 11.9949C2.00342 12.1888 2.05981 12.3786 2.16573 12.541C2.27164 12.7035 2.42251 12.8316 2.59996 12.9099L11.18 16.8199C11.4405 16.9387 11.7236 17.0002 12.01 17.0002C12.2964 17.0002 12.5794 16.9387 12.84 16.8199L21.42 12.9199C21.5974 12.8416 21.7483 12.7135 21.8542 12.551C21.9601 12.3886 22.0165 12.1988 22.0165 12.0049C22.0165 11.8109 21.9601 11.6212 21.8542 11.4587C21.7483 11.2963 21.5974 11.1681 21.42 11.0899L12.83 7.17987Z"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22 17.6499L12.83 21.8099C12.5694 21.9288 12.2864 21.9903 12 21.9903C11.7136 21.9903 11.4306 21.9288 11.17 21.8099L2 17.6499"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Pipeline
          </NavLink>
        </li>
      </ul>
      <ul className="sidebar-secondary-navigation flex flex-direction-column">
        <li className="theme-switch flex justify-content-center">
          <button
            className="theme-switch--dark active flex-center"
            aria-label="to dark mode"
            onClick={changeState}
          >
            <svg
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2372_104903)">
                <path
                  d="M9.09892 6.66666C8.73917 7.11349 8.54296 7.70527 8.55174 8.31705C8.56052 8.92882 8.7736 9.51268 9.14597 9.94533C9.51834 10.378 10.0209 10.6255 10.5474 10.6357C11.0739 10.6459 11.5833 10.418 11.9679 9.99999C11.9679 10.6593 11.7996 11.3037 11.4844 11.8519C11.1691 12.4001 10.721 12.8273 10.1968 13.0796C9.67258 13.3319 9.09574 13.3979 8.53922 13.2693C7.9827 13.1407 7.4715 12.8232 7.07027 12.357C6.66905 11.8908 6.39581 11.2969 6.28511 10.6503C6.17441 10.0037 6.23122 9.33346 6.44837 8.72438C6.66551 8.11529 7.03323 7.5947 7.50502 7.22842C7.97682 6.86215 8.5315 6.66666 9.09892 6.66666Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.09912 1.66666V3.33332"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.09912 16.6667V18.3333"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.00659 4.08334L5.01072 5.25001"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.1873 14.75L14.1914 15.9167"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.92676 10H3.36123"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.8369 10H16.2714"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.01072 14.75L4.00659 15.9167"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.1914 4.08334L13.1873 5.25001"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2372_104903">
                  <rect
                    width="17.2136"
                    height="20"
                    fill="white"
                    transform="translate(0.492188)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
          <button
            className="theme-switch--light flex-center"
            aria-label="to light mode"
            onClick={changeState}
          >
            <svg
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.73575 2.5C7.87975 3.49456 7.39885 4.84348 7.39885 6.25C7.39885 7.65652 7.87975 9.00544 8.73575 10C9.59175 10.9946 10.7527 11.5533 11.9633 11.5533C13.1739 11.5533 14.3349 10.9946 15.1909 10C15.1909 11.4834 14.8123 12.9334 14.103 14.1668C13.3937 15.4001 12.3855 16.3614 11.206 16.9291C10.0265 17.4968 8.72859 17.6453 7.47642 17.3559C6.22425 17.0665 5.07406 16.3522 4.1713 15.3033C3.26854 14.2544 2.65375 12.918 2.40468 11.4632C2.1556 10.0083 2.28344 8.50032 2.77201 7.12987C3.26058 5.75943 4.08795 4.58809 5.14949 3.76398C6.21102 2.93987 7.45905 2.5 8.73575 2.5Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </li>
        <li>
          <NavLink activeclassname="active" to="/setting">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 16V8.00002C20.9996 7.6493 20.9071 7.30483 20.7315 7.00119C20.556 6.69754 20.3037 6.44539 20 6.27002L13 2.27002C12.696 2.09449 12.3511 2.00208 12 2.00208C11.6489 2.00208 11.304 2.09449 11 2.27002L4 6.27002C3.69626 6.44539 3.44398 6.69754 3.26846 7.00119C3.09294 7.30483 3.00036 7.6493 3 8.00002V16C3.00036 16.3508 3.09294 16.6952 3.26846 16.9989C3.44398 17.3025 3.69626 17.5547 4 17.73L11 21.73C11.304 21.9056 11.6489 21.998 12 21.998C12.3511 21.998 12.696 21.9056 13 21.73L20 17.73C20.3037 17.5547 20.556 17.3025 20.7315 16.9989C20.9071 16.6952 20.9996 16.3508 21 16Z"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                stroke="#0BDEDE"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Settings
          </NavLink>
        </li>
        <li>
          <a href="/api/logout">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 20V6C18 5.46957 17.7893 4.96086 17.4142 4.58579C17.0391 4.21071 16.5304 4 16 4H8C7.46957 4 6.96086 4.21071 6.58579 4.58579C6.21071 4.96086 6 5.46957 6 6V20"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 20H22"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 12V12.01"
                stroke="#0BDEDE"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Log Out
          </a>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
