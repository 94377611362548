import React from "react";
import { Link } from "react-router-dom";

const Signup = () => {
  return (
    <div
      className="main-container"
      style={{ width: "100%", maxWidth: "100%", paddingBottom: "0" }}
    >
      <div className="flex login-page login-page--signup">
        <img src="assets/images/logo-large.svg" alt="logo large" />
        <form action autoComplete="off">
          <h2 className="form-title">Get Started</h2>
          <div className="input-group">
            <input
              type="text"
              placeholder="Username"
              className="input user-icon"
              autoComplete="none"
            />
          </div>
          <div className="input-group">
            <input
              type="text"
              placeholder="Last Name"
              className="input user-icon"
              autoComplete="none"
            />
          </div>
          <div className="input-group">
            <input
              type="text"
              placeholder="Connect Whatsapp Business"
              className="input contact-icon"
              autoComplete="none"
            />
          </div>
          <div className="input-group">
            <input
              type="email"
              placeholder="Email Adress"
              className="input email-icon"
              autoComplete="none"
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              placeholder="Password"
              className="input password-icon"
              autoComplete="none"
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              placeholder="Password"
              className="input password-icon"
              autoComplete="none"
            />
          </div>
          {/* <button type="submit" className="btn btn--full-width mt-25"> */}
          <Link to="/dashboard" className="btn btn--full-width mt-25">
            Sign Up
          </Link>
          {/* </button> */}
          {/* <div className="continue-with">Or continue with</div>
          <div className="btn-group">
            <button className="login-btn">
              <img src="assets/icons/icon-google.svg" alt="google icon" />
            </button>
            <button className="login-btn">
              <img src="assets/icons/icon-apple.svg" alt="google icon" />
            </button>
            <button className="login-btn">
              <img src="assets/icons/icon-facebook.svg" alt="google icon" />
            </button>
          </div> */}
          <div className="new-signup">
            Already have an account?
            <Link to="/login" className="btn">
              Sign In
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
