import React from "react";
import { Link } from "react-router-dom";

const IndividualPackage = () => {
  return (
    <div className="main-container" style={{ width: "100%" }}>
      <div className="flex options">
        <a href="#" className="page__logo">
          <img src="assets/images/logo-medium.svg" alt srcSet />
        </a>
        <div className="individual-package__container">
          <div className="options__container color-white">
            <Link to="/packages" className="btn-back">
              Back
              <img src="assets/icons/icon-back.svg" alt srcSet />
            </Link>
            <div className="packages individual-package">
              <div className="packages__card">
                <h3>Agent</h3>
                <h2>Basic Package </h2>
                <ul>
                  <li>50 Engagements</li>
                  <li>Broadcast Options</li>
                  <li>Auto Scheduler</li>
                  <li>Analytics</li>
                  <li>Pipeline</li>
                </ul>
                <div className="individual-package__price">
                  <div className="price">$699.6</div>
                  <p>Charged Annually</p>
                  <p className="flex switch-container">
                    Switch to Monthly Subscription
                    <label className="switch">
                      <input type="checkbox" />
                    </label>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="options__form"></div>
        </div>
      </div>
    </div>
  );
};

export default IndividualPackage;
