import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";

const EnterpriseSetting = () => {
  const [activeTab, setActiveTab] = useState("profile");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <main className="flex">
      <Sidebar title="Setting" />
      <div className="main-container">
        <Header />
        {/* main content */}
        <div className="dashboard">
          <div className="tabs flex">
            <div className="tab_links">
              <a
                href="#"
                className={`tab_link ${
                  activeTab === "profile" ? "active" : ""
                }`}
                onClick={() => handleTabClick("profile")}
                data-tab="profile"
              >
                PROFILE
              </a>
              <a
                href="#"
                className={`tab_link ${
                  activeTab === "account" ? "active" : ""
                }`}
                onClick={() => handleTabClick("account")}
                data-tab="account"
              >
                ACCOUNT
              </a>
              <a
                href="#"
                className={`tab_link ${
                  activeTab === "feedback_complaint" ? "active" : ""
                }`}
                onClick={() => handleTabClick("feedback_complaint")}
                data-tab="feedback_complaint"
              >
                FEEDBACK & COMPLAINTS
              </a>
              <a href="#" className="btn dashboard__btn">
                UPGRADE
              </a>
            </div>
            <div className="tabs_content">
              <div
                className={`profile ${activeTab === "profile" ? "active" : ""}`}
              >
                <div className="user_img">
                  <img
                    src="https://wac-cdn.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg?cdnVersion=1505"
                    alt="user"
                  />
                </div>
                <a href="#" className="btn dashboard__btn inline-block">
                  Change Profile
                </a>
                <form action="#" className="setting_form">
                  <div className="input-group">
                    <input type="text" />
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_49_19304)">
                        <path
                          d="M14.1665 2.49988C14.3787 2.24907 14.6413 2.04493 14.9373 1.90045C15.2334 1.75597 15.5566 1.67433 15.8862 1.66072C16.2158 1.64712 16.5447 1.70186 16.8518 1.82145C17.159 1.94104 17.4376 2.12286 17.6701 2.35531C17.9025 2.58777 18.0837 2.86576 18.202 3.17158C18.3202 3.4774 18.373 3.80433 18.357 4.13153C18.341 4.45873 18.2565 4.77903 18.1089 5.07202C17.9613 5.365 17.7539 5.62424 17.4998 5.83321L6.24984 17.0832L1.6665 18.3332L2.9165 13.7499L14.1665 2.49988Z"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.5 4.16675L15.8333 7.50008"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_49_19304">
                          <rect width={20} height={20} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="input-group">
                    <input type="text" />
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_49_19304)">
                        <path
                          d="M14.1665 2.49988C14.3787 2.24907 14.6413 2.04493 14.9373 1.90045C15.2334 1.75597 15.5566 1.67433 15.8862 1.66072C16.2158 1.64712 16.5447 1.70186 16.8518 1.82145C17.159 1.94104 17.4376 2.12286 17.6701 2.35531C17.9025 2.58777 18.0837 2.86576 18.202 3.17158C18.3202 3.4774 18.373 3.80433 18.357 4.13153C18.341 4.45873 18.2565 4.77903 18.1089 5.07202C17.9613 5.365 17.7539 5.62424 17.4998 5.83321L6.24984 17.0832L1.6665 18.3332L2.9165 13.7499L14.1665 2.49988Z"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.5 4.16675L15.8333 7.50008"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_49_19304">
                          <rect width={20} height={20} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="input-group">
                    <input type="text" />
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_49_19304)">
                        <path
                          d="M14.1665 2.49988C14.3787 2.24907 14.6413 2.04493 14.9373 1.90045C15.2334 1.75597 15.5566 1.67433 15.8862 1.66072C16.2158 1.64712 16.5447 1.70186 16.8518 1.82145C17.159 1.94104 17.4376 2.12286 17.6701 2.35531C17.9025 2.58777 18.0837 2.86576 18.202 3.17158C18.3202 3.4774 18.373 3.80433 18.357 4.13153C18.341 4.45873 18.2565 4.77903 18.1089 5.07202C17.9613 5.365 17.7539 5.62424 17.4998 5.83321L6.24984 17.0832L1.6665 18.3332L2.9165 13.7499L14.1665 2.49988Z"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.5 4.16675L15.8333 7.50008"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_49_19304">
                          <rect width={20} height={20} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="input-group">
                    <input type="text" />
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_49_19304)">
                        <path
                          d="M14.1665 2.49988C14.3787 2.24907 14.6413 2.04493 14.9373 1.90045C15.2334 1.75597 15.5566 1.67433 15.8862 1.66072C16.2158 1.64712 16.5447 1.70186 16.8518 1.82145C17.159 1.94104 17.4376 2.12286 17.6701 2.35531C17.9025 2.58777 18.0837 2.86576 18.202 3.17158C18.3202 3.4774 18.373 3.80433 18.357 4.13153C18.341 4.45873 18.2565 4.77903 18.1089 5.07202C17.9613 5.365 17.7539 5.62424 17.4998 5.83321L6.24984 17.0832L1.6665 18.3332L2.9165 13.7499L14.1665 2.49988Z"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.5 4.16675L15.8333 7.50008"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_49_19304">
                          <rect width={20} height={20} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="input-group">
                    <input type="text" />
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_49_19304)">
                        <path
                          d="M14.1665 2.49988C14.3787 2.24907 14.6413 2.04493 14.9373 1.90045C15.2334 1.75597 15.5566 1.67433 15.8862 1.66072C16.2158 1.64712 16.5447 1.70186 16.8518 1.82145C17.159 1.94104 17.4376 2.12286 17.6701 2.35531C17.9025 2.58777 18.0837 2.86576 18.202 3.17158C18.3202 3.4774 18.373 3.80433 18.357 4.13153C18.341 4.45873 18.2565 4.77903 18.1089 5.07202C17.9613 5.365 17.7539 5.62424 17.4998 5.83321L6.24984 17.0832L1.6665 18.3332L2.9165 13.7499L14.1665 2.49988Z"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.5 4.16675L15.8333 7.50008"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_49_19304">
                          <rect width={20} height={20} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="input-group">
                    <a href="#" className="btn dashboard__btn inline-block">
                      Save Changes
                    </a>
                    <a
                      href="#"
                      className="btn dashboard__btn_white inline-block"
                    >
                      Cancel
                    </a>
                  </div>
                </form>
              </div>
              <div
                className={`account ${activeTab === "account" ? "active" : ""}`}
              >
                <form action="#" className="setting_form">
                  <div className="input-group">
                    <label htmlFor="test">Agent Name</label>
                    <input type="text" />
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_49_19304)">
                        <path
                          d="M14.1665 2.49988C14.3787 2.24907 14.6413 2.04493 14.9373 1.90045C15.2334 1.75597 15.5566 1.67433 15.8862 1.66072C16.2158 1.64712 16.5447 1.70186 16.8518 1.82145C17.159 1.94104 17.4376 2.12286 17.6701 2.35531C17.9025 2.58777 18.0837 2.86576 18.202 3.17158C18.3202 3.4774 18.373 3.80433 18.357 4.13153C18.341 4.45873 18.2565 4.77903 18.1089 5.07202C17.9613 5.365 17.7539 5.62424 17.4998 5.83321L6.24984 17.0832L1.6665 18.3332L2.9165 13.7499L14.1665 2.49988Z"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.5 4.16675L15.8333 7.50008"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_49_19304">
                          <rect width={20} height={20} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="input-group">
                    <label htmlFor="#">Whatsapp Business Number</label>
                    <input type="text" />
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_49_19304)">
                        <path
                          d="M14.1665 2.49988C14.3787 2.24907 14.6413 2.04493 14.9373 1.90045C15.2334 1.75597 15.5566 1.67433 15.8862 1.66072C16.2158 1.64712 16.5447 1.70186 16.8518 1.82145C17.159 1.94104 17.4376 2.12286 17.6701 2.35531C17.9025 2.58777 18.0837 2.86576 18.202 3.17158C18.3202 3.4774 18.373 3.80433 18.357 4.13153C18.341 4.45873 18.2565 4.77903 18.1089 5.07202C17.9613 5.365 17.7539 5.62424 17.4998 5.83321L6.24984 17.0832L1.6665 18.3332L2.9165 13.7499L14.1665 2.49988Z"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.5 4.16675L15.8333 7.50008"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_49_19304">
                          <rect width={20} height={20} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="input-group">
                    <label htmlFor="test">Email Address</label>
                    <input type="text" />
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_49_19304)">
                        <path
                          d="M14.1665 2.49988C14.3787 2.24907 14.6413 2.04493 14.9373 1.90045C15.2334 1.75597 15.5566 1.67433 15.8862 1.66072C16.2158 1.64712 16.5447 1.70186 16.8518 1.82145C17.159 1.94104 17.4376 2.12286 17.6701 2.35531C17.9025 2.58777 18.0837 2.86576 18.202 3.17158C18.3202 3.4774 18.373 3.80433 18.357 4.13153C18.341 4.45873 18.2565 4.77903 18.1089 5.07202C17.9613 5.365 17.7539 5.62424 17.4998 5.83321L6.24984 17.0832L1.6665 18.3332L2.9165 13.7499L14.1665 2.49988Z"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.5 4.16675L15.8333 7.50008"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_49_19304">
                          <rect width={20} height={20} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="input-group">
                    <label htmlFor="test">Meeting Schedule Link </label>
                    <input type="text" />
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_49_19304)">
                        <path
                          d="M14.1665 2.49988C14.3787 2.24907 14.6413 2.04493 14.9373 1.90045C15.2334 1.75597 15.5566 1.67433 15.8862 1.66072C16.2158 1.64712 16.5447 1.70186 16.8518 1.82145C17.159 1.94104 17.4376 2.12286 17.6701 2.35531C17.9025 2.58777 18.0837 2.86576 18.202 3.17158C18.3202 3.4774 18.373 3.80433 18.357 4.13153C18.341 4.45873 18.2565 4.77903 18.1089 5.07202C17.9613 5.365 17.7539 5.62424 17.4998 5.83321L6.24984 17.0832L1.6665 18.3332L2.9165 13.7499L14.1665 2.49988Z"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.5 4.16675L15.8333 7.50008"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_49_19304">
                          <rect width={20} height={20} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="input-group">
                    <label htmlFor="test">Language</label>
                    <input type="text" />
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_49_19304)">
                        <path
                          d="M14.1665 2.49988C14.3787 2.24907 14.6413 2.04493 14.9373 1.90045C15.2334 1.75597 15.5566 1.67433 15.8862 1.66072C16.2158 1.64712 16.5447 1.70186 16.8518 1.82145C17.159 1.94104 17.4376 2.12286 17.6701 2.35531C17.9025 2.58777 18.0837 2.86576 18.202 3.17158C18.3202 3.4774 18.373 3.80433 18.357 4.13153C18.341 4.45873 18.2565 4.77903 18.1089 5.07202C17.9613 5.365 17.7539 5.62424 17.4998 5.83321L6.24984 17.0832L1.6665 18.3332L2.9165 13.7499L14.1665 2.49988Z"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.5 4.16675L15.8333 7.50008"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_49_19304">
                          <rect width={20} height={20} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="input-group">
                    <label htmlFor="test">Password</label>
                    <input type="text" />
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_49_19304)">
                        <path
                          d="M14.1665 2.49988C14.3787 2.24907 14.6413 2.04493 14.9373 1.90045C15.2334 1.75597 15.5566 1.67433 15.8862 1.66072C16.2158 1.64712 16.5447 1.70186 16.8518 1.82145C17.159 1.94104 17.4376 2.12286 17.6701 2.35531C17.9025 2.58777 18.0837 2.86576 18.202 3.17158C18.3202 3.4774 18.373 3.80433 18.357 4.13153C18.341 4.45873 18.2565 4.77903 18.1089 5.07202C17.9613 5.365 17.7539 5.62424 17.4998 5.83321L6.24984 17.0832L1.6665 18.3332L2.9165 13.7499L14.1665 2.49988Z"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.5 4.16675L15.8333 7.50008"
                          stroke="#BFBFBF"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_49_19304">
                          <rect width={20} height={20} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="input-group">
                    <a href="#" className="btn dashboard__btn inline-block">
                      Save Changes
                    </a>
                    <a
                      href="#"
                      className="btn dashboard__btn_white inline-block"
                    >
                      Cancel
                    </a>
                  </div>
                </form>
              </div>
              <div
                className={`feedback_complaint ${
                  activeTab === "feedback_complaint" ? "active" : ""
                }`}
              >
                <form action="#" className="setting_form">
                  <textarea
                    name="feedback_complaint"
                    id="feedback_complaint"
                    defaultValue={""}
                  />
                  <div className="input-group">
                    <a href="#" className="btn dashboard__btn inline-block">
                      Save Changes
                    </a>
                    <a
                      href="#"
                      className="btn dashboard__btn_white inline-block"
                    >
                      Cancel
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EnterpriseSetting;
