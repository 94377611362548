import React from "react";

import { useCSVDownloader } from "react-papaparse";

export default function CSVDownloader() {
  const { CSVDownloader, Type } = useCSVDownloader();

  return (
    <CSVDownloader
      type={Type.Button}
      filename={"contacts-template"}
      bom={true}
      config={{
        delimiter: ",",
      }}
      data={[
        {
          name: "John Doe",
          phone: "1234567890",
        },
        {
          name: "Tom Cook",
          phone: "1234567890",
        },
        {
          name: "Satya Smith",
          phone: "1234567890",
        },
        {
          name: "Jeff Gonzales",
          phone: "1234567890",
        },
      ]}
    >
      Download Contacts Template
    </CSVDownloader>
  );
}
